import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// Helpers
import { capitalize } from "@/_helpers/text_functions";
import { roundNumber } from "@/_helpers/number_functions";

// Services
import { useGetLatestInvoiceQuery } from "@/repositories/invoice";
import { selectIsSubscribed, selectIsTrial, selectSubscriptionType } from "@/store/subscription";

// Styles
import { ArrowRight } from "@carbon/icons-react";
import logo from '@/assets/logos/1fit_logo_icon.png';

// Components
import Link from "@/components/link";
import Button from "@/components/button";
import { Box, Container, Skeleton } from "@mui/material";
import { InlineNotification } from "@/components/notification/notification";


const SubscribedPage: FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const isTrial = useSelector(selectIsTrial);
    const isSubscribed = useSelector(selectIsSubscribed);
    const subscriptionPlan = useSelector(selectSubscriptionType);
    const { data: invoice, isLoading } = useGetLatestInvoiceQuery(undefined);
    
    const nextInvoiceDate = invoice?.period_end ? new Date(invoice.period_end).toLocaleDateString() : '';
    const amount: number = invoice?.total ? invoice.total / 100 : 0;
    const discount: number = invoice?.discount ?? 0;
    const discountValue: number = roundNumber(amount * (discount / 100), 2);

    useEffect(() => {
        if (!isSubscribed) navigate('/setup/subscription');
    }, [isSubscribed, navigate])

    const submitForm = async () => {
        navigate('/setup/profile');
    }
    
    return (
        <Container sx={{'@media (max-width: 768px)': {px: 0}}}>
            <Box maxWidth="752px">

                <Box marginBottom="16px" sx={{textAlign: 'left', '@media (min-width: 768px)': {display: 'none'}}}>
                    <span className="body-01" style={{color: 'var(--text-secondary)'}}>{t('pages.setup.subscription.complete.mobileText')}</span>
                </Box>

                {/* Notification card */}
                <InlineNotification 
                    type="success" 
                    title={t(`pages.setup.subscription.complete.${isTrial ? 'notificationTrialTitle' : 'notificationTitle'}`)}
                    message={t(`pages.setup.subscription.complete.${isTrial ? 'notificationTrialSubtitle' : 'notificationSubtitle'}`)}
                    spacingBottom="16px"
                    />

                {/* Confirmation card */}
                <Box display="flex" padding="24px" borderRadius="12px" border="solid 1px var(--border-subtle-02)">
                    <Box sx={{maxWidth: '80px', maxHeight: '80px', '@media (max-width: 768px)': {maxWidth: '50px', maxHeight: '50px'}}}>
                    <img src={logo} width="100%" height="100%" />
                    </Box>
                    <Box display="flex" flexDirection="column" flexGrow={1} justifyContent="center" alignItems="start" textAlign="left" paddingLeft="16px">
                        <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between" alignItems="center">
                            <span className="heading-05 HideOnMobile">{t(`pages.setup.subscription.complete.${isTrial ? 'cardTrialTitle' : 'cardTitle'}`, {plan: capitalize(subscriptionPlan)})}</span>
                            {isTrial ?
                                <span className="heading-06">{t('pages.setup.subscription.complete.cardTrialText')}</span> :
                                <span className="heading-06">£{amount.toLocaleString("en", { minimumFractionDigits: 2 })}</span>}
                        </Box>  
                        <span className="body-02-compact">{t('pages.setup.subscription.complete.cardSubtitle', {date: nextInvoiceDate})}</span>
                    </Box>
                </Box>

                {/* Invoice details */}
                {!isTrial && <>
                    {/* Spacer */}
                    <Box height="16px"></Box>
                    <Box display="flex" flexGrow={1} justifyContent="space-between">
                        <span className="body-01-compact">{t('billing.subtotal')}</span>
                        {isLoading ? 
                            <Skeleton variant="rectangular" width={55} height={22} /> : 
                            <span className="body-01-compact">£{amount.toLocaleString("en", { minimumFractionDigits: 2 })}</span>
                        }
                    </Box>
                    <Box display="flex" flexGrow={1} justifyContent="space-between">
                        <span className="body-01-compact">{t('billing.discount', {percentage: `${discount ? `${discount}%` : 'N/A'}`})}</span>
                        <span className="body-01-compact">-£{discountValue.toLocaleString("en", { minimumFractionDigits: 2 })}</span>
                    </Box>

                    {/* Divider */}
                    <Box display="flex" flexGrow={1} border="solid 0.5px var(--border-subtle-00)" margin="16px 0 16px 0" />
                    
                    {/* Total */}
                    <Box display="flex" flexGrow={1} justifyContent="space-between">
                        <span className="heading-05">{t('billing.total', {percentage: `${discount ? `${discount}%` : 'N/A'}`})}</span>
                        <span className="heading-05">£{(amount - discountValue).toLocaleString("en", { minimumFractionDigits: 2 })}</span>
                    </Box>
                </>}

                {/* Spacer */}
                <Box height="40px"></Box>

                <Box display="flex" flexGrow={1}>
                    <Link to="https://1fit.com/terms" newWindow label={t('pages.setup.subscription.complete.terms')} icon={<ArrowRight />} />
                    <Box width="32px" />
                    {!isTrial && <Link to={invoice?.invoice_link} newWindow label={t('billing.viewInvoice')} icon={<ArrowRight />} />}
                </Box>

                {/* Spacer */}
                <Box height="32px" />

                {/* Actions */}
                <Box display="flex" flexGrow={1} height="24px" width="100%" justifyContent="end" paddingBottom="24px">
                    <Button
                        label={t('components.buttons.continue')}
                        endIcon={<ArrowRight />}
                        onClick={submitForm}
                        sx={{'@media (max-width: 768px)': {width: '100%'}}}
                        />
                </Box>

            </Box>
        </Container>
    )
}

export default SubscribedPage;