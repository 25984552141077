import dayjs from "dayjs";
import { t } from "i18next";


export function formatDateAsText(date: string): string {
  if (!date || date === '') return t('timeDate.today');
  if (dayjs().isSame(dayjs(date.substring(0,10)), 'day')) {
      return t('timeDate.today');
  } else if (dayjs().subtract(1, 'day').isSame(dayjs(date.substring(0,10)), 'day')) {
      return t('timeDate.yesterday');
  } else if (dayjs().add(1, 'day').isSame(dayjs(date.substring(0,10)), 'day')) {
      return t('timeDate.tomorrow');
  } else {
      return dayjs().startOf('day').to(date.substring(0,10)); // Default to relative time
  }
}

export function getOrdinalSuffix(n: number): string {
  const value = n % 100;

  if (value >= 11 && value <= 13) {
    return `${n}th`;
  }

  switch (value % 10) {
    case 1:
      return `${n}st`;
    case 2:
      return `${n}nd`;
    case 3:
      return `${n}rd`;
    default:
      return `${n}th`;
  }
}

export function formatDateTimeShort(date: string): string {
  const day = dayjs(date).format('DD MMM');
  const time = dayjs(date).format('HH:mm');
  return t('timeDate.textOutputs.dateAtTimeShort', { date: day, time: time });
}