import dayjs from "dayjs";
import { FC } from "react";
import { useTranslation } from "react-i18next";

// Interfaces
import { ICheckinSubmission } from "@/interfaces/checkin_submission/checkin_submission";

// Components
import Avatar from "@/components/avatar";
import { Box, Skeleton } from "@mui/material";
import { formatDateTimeShort } from "@/_helpers/date_functions";


interface CheckinCardProps {
    checkin: ICheckinSubmission;
    sortCriteria?: 'submitted'|'generated';
    isLoading?: boolean;
    onClick?: () => void;
}

const CheckinCard: FC<CheckinCardProps> = ({
    checkin,
    sortCriteria = 'submitted',
    isLoading = false,
    onClick
}) => {

    const { t } = useTranslation();
    const daysSince = (date: string) => dayjs().startOf('day').diff(dayjs(date).startOf('day'), 'day');
    const hoursSince = (date: string) => dayjs().diff(date, 'hour');
    const canClick = checkin.status === 'to_do' || checkin.status === 'in_progress' || checkin.status === 'done';
    const showSubmitted = checkin.status === 'to_do' || checkin.status === 'in_progress';
    const dateToUse = sortCriteria == 'submitted' ? checkin.submitted_at : checkin.created_at;

    const setTag = () => {
        switch(checkin.status) {
            case 'overdue':
                return <div className="label-text-02 text-error">{t('pages.checkins.statusTitles.due', {count: daysSince(checkin.created_at)})}</div>
            case 'due_today':
                return <div className="label-text-02">{t('pages.checkins.statusTitles.awaiting')}</div>
            case 'to_do':
                return daysSince(dateToUse) === 0 ?
                    <div className="label-text-02">{t(`pages.checkins.statusTitles.${sortCriteria}`, {count: daysSince(dateToUse)})}</div> :
                    <div className="label-text-02 text-error">{t(`pages.checkins.statusTitles.${sortCriteria}`, {count: daysSince(dateToUse)})}</div>;
            case 'in_progress':
                return daysSince(checkin.submitted_at) === 0 ?
                    <div className="label-text-02">{t('pages.checkins.statusTitles.submittedToday')}</div> :
                    <div className="label-text-02 text-error">{t('pages.checkins.statusTitles.submitted', {count: daysSince(checkin.submitted_at)})}</div>;
            case 'done':
                return <div className="label-text-02">{t('pages.checkins.statusTitles.completed', {count: hoursSince(checkin.reviewed_at)})}</div>;
            default:
                return 'outline';
        }
    };

    return (
        <Box 
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            maxHeight="70px" 
            padding="16px"
            boxSizing="border-box"
            borderRadius="6px" 
            border="solid 1px var(--border-subtle-01)"
            sx={{bgcolor: 'var(--layer-01)', cursor: canClick ? 'pointer' : 'default'}}
            onClick={canClick ? onClick : undefined}
        >

            <Avatar
                size="medium"
                name={checkin.client.name}
                src={checkin.client.avatar}
                isLoading={isLoading}
            />

            <Box flexGrow={1} ml={2} textAlign='left' overflow='hidden' sx={{'& > div': {overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}}>
                {isLoading ?
                    <Skeleton variant="rectangular" width="120px" height="22px" sx={{marginBottom: '4px'}} /> : 
                    <div className="body-02-compact" style={{marginBottom: 4}}>{checkin.client.name}</div>}
                {isLoading ? <Skeleton variant="rectangular" width="140px" height="20px" /> : setTag()}
                {showSubmitted && isLoading ? <Skeleton variant="rectangular" width="140px" height="20px" /> :
                    showSubmitted ? <div className="label-text-02" style={{color: 'var(--text-secondary)', marginTop: '4px'}}>{formatDateTimeShort(dateToUse)}</div> : null}
            </Box>

        </Box>
    )
}

export default CheckinCard;