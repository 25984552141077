import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

// Services and interfaces
import { useGetCheckinSubmissionsQuery } from "@/repositories/checkin_submission";

// Components
import Toggle from "@/components/toggle";
import { Options } from "@/components/menu";
import { Box, Container } from "@mui/material";
import MenuButton from "@/components/menu_button";
import IconButton from "@/components/icon_button";
import { SortAscending } from "@carbon/icons-react";
import SecondaryToolbar from "@/components/secondary_toolbar";
import CheckinColumn, { BoardColumn } from "./checkin_column";


const CheckinBoardPage: FC = () => {

    const { t } = useTranslation();
    const [sortOrder, setSortOrder] = useState<boolean>(true);
    const [sortCriteria, setSortCriteria] = useState<'submitted'|'generated'>('submitted');
    const { data: list, isLoading } = useGetCheckinSubmissionsQuery();

    const { control, watch } = useForm({
        defaultValues: {
            showOverdue: true,
        }
    });

    const showOverdue = watch('showOverdue');

    const overdueCheckins = list?.filter((checkin) => checkin.status === 'overdue');
    const dueCheckins = list?.filter((checkin) => checkin.status === 'due_today');
    const todoCheckins = list?.filter((checkin) => checkin.status === 'to_do');
    const inProgressCheckins = list?.filter((checkin) => checkin.status === 'in_progress');
    const doneCheckins = list?.filter((checkin) => checkin.status === 'done');

    const board: BoardColumn[] = [
        {status: 'overdue', checkins: overdueCheckins ?? [], label: t('components.tag.labels.overdue'), color: 'red'},
        {status: 'due_today', checkins: dueCheckins ?? [], label: t('components.tag.labels.dueToday'), color: 'high-contrast'},
        {status: 'to_do', checkins: todoCheckins ?? [], label: t('components.tag.labels.todo'), color: 'outline', outlined: true},
        {status: 'in_progress', checkins: inProgressCheckins ?? [], label: t('components.tag.labels.inProgress'), color: 'grey'},
        {status: 'done', checkins: doneCheckins ?? [], label: t('components.tag.labels.done'), color: 'green'},
    ];

    const sortOtions: Options[] = [
        {key: 'submitted', name: t('components.select.options.checkinSort.dateSubmitted'), action: () => setSortCriteria('submitted')},
        {key: 'generated', name: t('components.select.options.checkinSort.dateGenerated'), action: () => setSortCriteria('generated')},
    ];

    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 40px)" sx={{overflow: 'hidden'}}>

            <SecondaryToolbar
                kind="primary"
                title={t('pages.checkins.title')}
                slot={
                    <Box display="flex" alignItems="center" columnGap="16px">
                        <Toggle name="showOverdue" control={control} label={t('components.toggle.showOverdue')} />
                        <Box display="flex" alignItems="center" columnGap="8px">
                            <span className="label-text-01" style={{color: 'var(--text-secondary)', marginRight: '4px'}}>{t('pages.checkinSubmission.sortToDoBy')}</span>
                            <MenuButton
                                size="small"
                                kind="tertiary"
                                label={sortOtions.find(option => option.key === sortCriteria)?.name}
                                options={sortOtions}
                                activeOption={sortCriteria}
                                />
                            <IconButton
                                size="small"
                                kind="tertiary"
                                icon={<SortAscending style={{transform: sortOrder ? 'unset' : 'rotate(180deg) scaleX(-1)'}} />}
                                onClick={() => setSortOrder(!sortOrder)}
                                />
                        </Box>

                    </Box>
                }
                />

            <Box height='100%' overflow='auto'>
            <Container 
                sx={{
                    display: 'flex',
                    columnGap: '16px', 
                    padding: '24px 0',
                    overflowY: 'auto'
                }}>

                {board.map((col, i) => {
                    if (!showOverdue && col.status === 'overdue') return null;
                    
                    return (
                        <CheckinColumn 
                            key={col.status} 
                            column={col} 
                            index={i}
                            sortOrder={sortOrder}
                            sortCriteria={sortCriteria} 
                            isLoading={isLoading} 
                            />
                    )}
                )}

            </Container>
            </Box>

        </Box>
    )
}

export default CheckinBoardPage;