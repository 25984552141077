import baseApi from '@/repositories/base_api';
import { IAPIResponse } from '@/interfaces/api/response';
import { buildQueryString } from '@/_helpers/url_builder';
import { ISubscription } from '@/interfaces/subscription/subscription';
import { ISubscriptionForm } from '@/interfaces/subscription/subscription_form';
import { ISubscriptionResponse } from '@/interfaces/subscription/subscription_response'
import { ISubscriptionCancellationForm } from '@/interfaces/subscription_cancellation/subscription_cancellation_form';


export const subscriptionApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getSubscription: build.query<ISubscription, void>({
            query: () => ({ url: '/subscription', method: 'get' }),
            transformResponse: (response: IAPIResponse<ISubscription>) => response.data,
            providesTags: ['Subscription']
        }),
        createSubscription: build.mutation<ISubscription, ISubscriptionForm>({
            query: (data) => ({ url: '/subscription', method: 'post', data: data}),
            transformResponse: (response: IAPIResponse<ISubscription>) => response.data,
        }),
        changeSubscription: build.mutation<ISubscription, ISubscriptionForm>({
            query: (data) => ({ url: '/subscription/change', method: 'post', data: data }),
            transformResponse: (response: IAPIResponse<ISubscription>) => response.data,
            invalidatesTags: ['Invoice.upcoming', 'Invoice.list']
        }),
        cancelDowngradeSubscription: build.mutation<ISubscription, void>({
            query: () => ({ url: '/subscription/change/cancel', method: 'post' }),
            transformResponse: (response: IAPIResponse<ISubscription>) => response.data,
            invalidatesTags: ['Invoice.upcoming']
        }),
        redeemCancellationOffer: build.mutation<ISubscriptionResponse, Record<string, any>>({
            query: (params) => ({ url: buildQueryString('/subscription/redeem', params), method: 'post' }),
            transformResponse: (response: IAPIResponse<ISubscriptionResponse>) => response.data,
        }),
        cancelSubscription: build.mutation<ISubscription, ISubscriptionCancellationForm>({
            query: (data) => ({ url: '/subscription/cancel', method: 'post', data: data }),
            transformResponse: (response: IAPIResponse<ISubscription>) => response.data,
            invalidatesTags: ['Invoice.upcoming']
        }),
        renewSubscription: build.mutation<ISubscription, void>({
            query: () => ({ url: '/subscription/renew', method: 'post' }),
            transformResponse: (response: IAPIResponse<ISubscription>) => response.data,
            invalidatesTags: ['Invoice.upcoming']
        })
    }),
})

export const { 
    useGetSubscriptionQuery, 
    useCreateSubscriptionMutation, 
    useChangeSubscriptionMutation,
    useCancelDowngradeSubscriptionMutation,
    useRedeemCancellationOfferMutation,
    useCancelSubscriptionMutation, 
    useRenewSubscriptionMutation 
} = subscriptionApi;