import { FC } from "react";
import { useTranslation } from "react-i18next";

// Interfaces
import IInvoice from "@/interfaces/invoices/invoice";

// Components
import { Box } from "@mui/material";
import Modal from "@/components/modal/modal";


interface _InvoiceBreakdownModalProps {
    open: boolean;
    onClose: () => void;
    invoice: IInvoice;
}


const InvoiceBreakdownModal: FC<_InvoiceBreakdownModalProps> = ({
    open,
    onClose,
    invoice
}) => {

    const { t } = useTranslation();
    const lineItems = [...invoice.line_items].sort((a, b) => b.total - a.total);
    
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={t('modals.invoiceBreakdown.title')}
            showClose
            children={
                <Box display="flex" flexDirection="column" width="448px">
                                      
                    {lineItems.map((item) => 
                        <Box key={item.id} display="flex" justifyContent="space-between">
                            <span className="body-02-compact">{t(`enums.invoiceItem.${item.product}`, {clients: invoice.line_items.find((item) => item.product == 'standard')?.quantity})}</span>
                            <span className="body-02-compact">{`£${item.total / 100}`}</span>
                        </Box>)}

                    <Box height="32px" />
                    <Box display="flex" justifyContent="space-between">
                        <span className="heading-07">{t('modals.invoiceBreakdown.total')}</span>
                        <span className="heading-07">{`£${invoice.total / 100}`}</span>
                    </Box>
                </Box>
            }
        />
    )
}

export default InvoiceBreakdownModal;