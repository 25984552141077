import { t } from "i18next";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

// Helpers and constants
import showToast from "@/_lib/toast";
import { capitalize } from "@/_helpers/text_functions";
import { subscriptionTiers } from "@/_constants/subscription";

// Services and interfaces
import { selectHasTeam } from "@/store/team";
import { selectCoachId } from "@/store/coach";
import { ApiError } from "@/interfaces/api/error";
import { Plan } from "@/interfaces/subscription/subscription";
import { selectSubscriptionType } from "@/store/subscription";
import { selectTotalActiveClients } from "@/repositories/client";
import { useGetUpcomingInvoiceQuery } from "@/repositories/invoice";
import { useGetPaymentMethodsQuery } from "@/repositories/payment_methods";
import { useChangeSubscriptionMutation } from "@/repositories/subscription";
import IChangeSubscriptionForm, { changeSubscriptionFormSchema } from "@/interfaces/subscription/subscription_form";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Modal from "@/components/modal/modal";
import SelectPaymentMethod from "./select_payment_method";
import SubscriptionOptions from "@/components/billing/subscription_options";


interface _ChangeSubscriptionModalProps {
    open: boolean;
    onClose: () => void;
}

const ChangeSubscriptionModal: FC<_ChangeSubscriptionModalProps> = ({
    open,
    onClose
}) => {

    const [changeSubscription, { isLoading: isChangingSubscription }] = useChangeSubscriptionMutation();
    
    const currentPlan = useSelector(selectSubscriptionType);
    const formMethods = useForm<IChangeSubscriptionForm>({
        resolver: zodResolver(changeSubscriptionFormSchema),
        mode: 'onBlur',
        defaultValues: {
            payment_method: undefined,
            plan: currentPlan
        }
    });
    const selectedPlan = formMethods.watch('plan');
    const isDowngrade = subscriptionTiers[selectedPlan] < subscriptionTiers[currentPlan];

    const handleSubmitChange = (data: IChangeSubscriptionForm) => {
        const payload = {
            plan: data.plan,
            payment_method_id: String(data.payment_method.id)
        }
        changeSubscription(payload).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.billing.subscriptionChanged.title', {context: `${isDowngrade}`}),
                message: t('notifications.billing.subscriptionChanged.message', {context: `${isDowngrade}`, plan: capitalize(selectedPlan)})
            })
            formMethods.reset();
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Modal
            open={open}
            onClose={isChangingSubscription ? undefined : onClose}
            showClose
            title={t('modals.changeSubscription.title')}
            text={t('modals.changeSubscription.text')}
            children={
                <FormProvider {...formMethods}>
                    <ChangeSubscriptionForm currentPlan={currentPlan} isChanging={isChangingSubscription} />
                </FormProvider>}
            action1={{
                label: t('components.buttons.confirmChange'),
                icon: <ArrowRight />,
                loading: isChangingSubscription,
                disabled: currentPlan == selectedPlan,
                onClick: formMethods.handleSubmit(handleSubmitChange),
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isChangingSubscription,
                onClick: () => onClose()
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '780px', width: '100%', background: 'var(--background)'}}}
            />
    )
}

export default ChangeSubscriptionModal;

interface _ChangeSubscriptionFormProps {
    currentPlan: Plan;
    isChanging: boolean;
}

const ChangeSubscriptionForm: FC<_ChangeSubscriptionFormProps> = ({
    currentPlan,
    isChanging,
}) => {

    const coachId = useSelector(selectCoachId);
    const hasTeam = useSelector(selectHasTeam);
    const { data: paymentMethods, isLoading: isLoadingPaymentMethods } = useGetPaymentMethodsQuery(undefined);
    const { data: invoice } = useGetUpcomingInvoiceQuery(undefined);
    const { watch, setValue } = useFormContext<IChangeSubscriptionForm>();

    const selectedPlan = watch('plan');
    const selectedCard = watch('payment_method');

    const isStandard = selectedPlan == 'standard';
    const isEnterpriseAnnual = selectedPlan == 'enterprise_annual';
    const isUpgrade = subscriptionTiers[selectedPlan] > subscriptionTiers[currentPlan];
    const nextInvoiceDate = invoice ? new Date(invoice.date).toLocaleDateString() : '-';
    const totalActiveClients = useSelector((state) => selectTotalActiveClients(state, hasTeam ? coachId : undefined));

    const totalDue = (): number => {
        if (selectedPlan == 'standard') return 40 + (totalActiveClients <= 10 ? 0 : totalActiveClients - 10 * 4);
        if (selectedPlan == 'enterprise') return 225;
        return 2100;
    }

    const billingInterval = (): string => {
        if (selectedPlan == 'enterprise_annual') return t('timeDate.year').toLowerCase();
        return t('timeDate.month').toLowerCase();
    }

    const handleChangePlan = (plan: Plan) => {
        setValue('plan', plan, {shouldDirty: true});
    }

    useEffect(() => {
        if (paymentMethods && !selectedCard) {
            const card = paymentMethods.find((item) => item.default == true);
            if (card) {
                setValue('payment_method', card);
            }
        }
    }, [paymentMethods, selectedCard])

    return (
        <Box display="flex" flexDirection="column" width="100%" rowGap="16px">

            {/* Plan options */}
            <SubscriptionOptions
                selected={selectedPlan}
                currentPlan={currentPlan}
                disabled={isChanging}
                onChange={handleChangePlan}
                />

            {/* Payment header */}
            <span className="heading-06">{t('modals.changeSubscription.selectPaymentMethod')}</span>

            {/* Payment methods */}
            <SelectPaymentMethod
                selectedCard={selectedCard}
                paymentMethods={paymentMethods ?? []}
                isLoading={isLoadingPaymentMethods}
                disabled={isChanging}
                />

            {/* Confirm text */}
            <Box display="flex" flexDirection="column" rowGap="8px" minHeight="135px">

                {currentPlan !== selectedPlan && <>
                <Box display="flex" justifyContent="space-between">
                    <span className="heading-06">{t('modals.changeSubscription.totalDue', {
                        context: `${isUpgrade}`, 
                        date: nextInvoiceDate
                    })}</span>
                    <span className="heading-06">£{totalDue()}</span>
                </Box>

                <span className="body-02" style={{whiteSpace: 'pre-wrap'}}>{t(`modals.changeSubscription.${isUpgrade ? 'confirmationTextUpgrade' : 'confirmationTextDowngrade'}`, {
                    context: `${isUpgrade ? isEnterpriseAnnual : isStandard}`, 
                    date: nextInvoiceDate,
                    amount: totalDue(),
                    interval: billingInterval()
                })}</span>
                </>}

            </Box>

        </Box>
    )
}
