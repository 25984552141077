import { z } from "zod";
import { t } from "i18next";
import ruleset, { addIssue } from "@/_helpers/ruleset";

export interface IProductForm {
    name: string;
    description: string;
    type: string;
    subtype: string;
    currency: string;
    price: IPrice;
    schedule?: ISchedule;
}

export interface IPrice {
    unit_amount?: number|string;
    interval: string;
    interval_count: number;
}

interface ISchedule {
    end_behaviour: string;
    phases: IPhase[];
}

interface IPhase {
    unit_amount: number|string;
    interval: string;
    interval_count: number;
    iterations: number;
}

export const productFormSchema = z.object({
    name: ruleset.required,
    description: ruleset.stringOptional,
    type: ruleset.required,
    subtype: ruleset.required,
    currency: ruleset.required,
    price: z.object({
        unit_amount: ruleset.numberOptional,
        interval: ruleset.selectOptional,
        interval_count: ruleset.numberOptional,
    }, {message: t('inputs.errors.required')}),
    schedule: z.object({
        end_behaviour: ruleset.selectOptional,
        phases: z.array(z.object({
            unit_amount: ruleset.numberOptional,
            interval: ruleset.selectOptional,
            interval_count: ruleset.numberOptional,
            iterations: ruleset.numberOptional
        }))
    })
}).superRefine((data, ctx) => {
    if (data.subtype == 'recurring') {
        if (data.price.unit_amount !== 0 && !data.price.unit_amount) addIssue(ctx, ['price', 'unit_amount'], t('inputs.errors.required'))
        if (!data.price.interval_count) addIssue(ctx, ['price', 'interval_count'], t('inputs.errors.required'))
        if (!data.price.interval) addIssue(ctx, ['price', 'interval'], t('inputs.errors.required'))
    } else if (data.subtype == 'staged') {
        if (!data.schedule?.end_behaviour) addIssue(ctx, ['schedule', 'end_behaviour'], t('inputs.errors.required'))
        data.schedule?.phases.forEach((phase, phaseIndex) => {
            const path = ['schedule', 'phases', phaseIndex]
            if (phase.unit_amount !== 0 && !phase.unit_amount) addIssue(ctx, [...path, 'unit_amount'], t('inputs.errors.required'))
            if (!phase.interval_count) addIssue(ctx, [...path, 'interval_count'], t('inputs.errors.required'))
            if (!phase.interval) addIssue(ctx, [...path, 'interval'], t('inputs.errors.required'))
            if (!phase.iterations) addIssue(ctx, [...path, 'iterations'], t('inputs.errors.required'))
        })
    }
});