import { FC, useState } from "react";

// Components
import Box from "@mui/material/Box";
import OptionTabs from "./option_tabs";
import MealOption from "./meal_option";


interface _MealOptionProps {
    dayIndex: number;
    mealIndex: number;
}

const MealOptions: FC<_MealOptionProps> = ({
    dayIndex, 
    mealIndex 
}) => {

    const [optionIndex, setOptionIndex] = useState<number>(0);
    
    return (
        <Box key={optionIndex}>
            <OptionTabs 
                current={optionIndex} 
                setCurrent={setOptionIndex} 
                dayIndex={dayIndex}
                mealIndex={mealIndex}
            />

            <MealOption 
                dayIndex={dayIndex} 
                mealIndex={mealIndex}
                optionIndex={optionIndex}
                />
        </Box>
    )
}

export default MealOptions;