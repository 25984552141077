import { FC } from "react";
import { t } from "i18next";
import { useFormContext } from "react-hook-form";

// Helpers
import { capitalize } from "@/_helpers/text_functions";

// Components
import { Box, Skeleton } from "@mui/material";
import SelectInput from "@/components/select_input";
import CardIcon, { CardKey } from "@/components/card_icon";
import IPaymentMethod from "@/interfaces/payment_methods/payment_method";


interface _SelectPaymentMethodProps {
    selectedCard?: IPaymentMethod;
    paymentMethods: IPaymentMethod[];
    isLoading: boolean;
    disabled?: boolean;
}

const SelectPaymentMethod: FC<_SelectPaymentMethodProps> = ({
    selectedCard,
    paymentMethods,
    isLoading,
    disabled = false
}) => {

    const { control } = useFormContext();

    return (
        <Box display="flex" alignItems="center" padding="16px 32px" borderRadius="6px" border="solid 1px var(--border-subtle-01)" bgcolor="var(--layer-01)">

            {isLoading || !selectedCard ?
                <Skeleton variant="rounded" width="70px" height="48px" />:
                <CardIcon type={selectedCard?.brand as CardKey} size="large" />}

            <Box width="32px" />

            <SelectInput
                name="payment_method"
                control={control}
                label={t('components.select.titles.paymentMethod')}
                items={paymentMethods}
                renderLabel={(item) => `${capitalize(item.brand)} - ${t('billing.endsWith', {last4: item.last_four})}`}
                returnObject
                gutter="0"
                fullWidth
                itemsLoading={isLoading}
                disabled={disabled}
                sx={{maxWidth: '300px', width: '100%'}}
                />
        </Box>
    )
}

export default SelectPaymentMethod;