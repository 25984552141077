import { t } from "i18next";
import { useForm } from "react-hook-form";
import { ChangeEvent, FC, useEffect } from "react";

// Interfaces
import { Plan } from "@/interfaces/subscription/subscription";

// Components
import Tag from "@/components/tag";
import Toggle from "@/components/toggle";
import { Box, RadioGroup, Radio } from "@mui/material";


interface _SubscriptionOptionsProps {
    selected: Plan;
    currentPlan?: Plan;
    disabled?: boolean;
    onChange: (value: Plan) => void;
}

const SubscriptionOptions: FC<_SubscriptionOptionsProps> = ({
    selected,
    currentPlan,
    disabled = false,
    onChange
}) => {

    const isStandard = selected == 'standard';
    const isEnterprise = selected.includes('enterprise');
    const isEnterpriseAnnual = selected.includes('enterprise_annual');

    const { control, watch, setValue } = useForm({
        defaultValues: {
            annual: isEnterpriseAnnual
        }
    });
    const annual = watch('annual');

    const handleSelectPlan = (event: ChangeEvent<HTMLInputElement>) => {
        const plan = event.target.value as Plan;
        onChange(plan);
    }

    useEffect(() => {
        if (isStandard) return;
        if (annual) {
            onChange('enterprise_annual');
        } else {
            onChange('enterprise');
        }
    }, [annual])

    useEffect(() => {
        if (isStandard) setValue('annual', false);
    }, [selected])

    return (
        <Box display="flex" flexDirection="column" alignItems="flex-start">

            {/* Title */}
            <span className="heading-06" style={{color: 'var(--text-secondary)'}}>{t('pages.setup.subscription.form.selectPlan')}</span>
            
            {/* Spacing */}
            <Box height="16px" />

            {/* Subscription options */}
            <RadioGroup
                defaultValue="standard"
                name="radio-buttons-group"
                sx={{width: '100%', rowGap: '8px'}}
                >
                {/* Standard Plan */}
                <Box 
                    display="flex" 
                    flexDirection="column" 
                    width="100%" 
                    padding="16px"
                    bgcolor={isStandard ? 'var(--notification-info-background)' : 'var(--layer-01)'}
                    borderRadius="6px"
                    border="solid 1px"
                    borderColor={isStandard ? 'var(--focus)' : 'var(--border-subtle-01)'}
                    boxSizing="border-box"
                    onClick={() => onChange('standard')}
                    sx={{cursor: isStandard ? 'unset' : 'pointer'}}
                    >
                    <Box display="flex" flexDirection="row" width="100%" alignItems="center" mb="16px">
                        <span className="heading-07-compact">{t('components.subscriptionOptions.standard.title')}</span>
                        <Box display="flex" flexGrow={1} />
                        {currentPlan == 'standard' ?
                            <Tag
                                label={t('components.tag.labels.currentPlan')}
                                colour="blue"
                                outlined
                                sx={{mb: '2px'}}
                                /> :
                                <span className="body-02-compact">{t('components.subscriptionOptions.standard.price')}</span>}
                        <Radio 
                            value="standard" 
                            size="small" 
                            checked={isStandard} 
                            disabled={disabled}
                            onChange={handleSelectPlan} 
                            sx={{
                                padding: 0, 
                                marginLeft: '16px',
                                '& .MuiSvgIcon-root': {color: isStandard ? 'var(--focus)' : 'unset'}
                            }} 
                            />
                    </Box>
                    <span className="body-02-compact" style={{textAlign: 'left'}}>{t('components.subscriptionOptions.standard.description')}</span>
                </Box>

                {/* Enterprise Plan */}
                <Box 
                    display="flex" 
                    flexDirection="column" 
                    width="100%" 
                    padding="16px"
                    bgcolor={isEnterprise ? 'var(--notification-info-background)' : 'var(--layer-01)'}
                    borderRadius="6px"
                    border="solid 1px"
                    borderColor={isEnterprise ? 'var(--focus)' : 'var(--border-subtle-01)'}
                    boxSizing="border-box"
                    onClick={() => !isStandard ? undefined : onChange('enterprise')}
                    sx={{cursor: !isStandard ? 'unset' : 'pointer'}}
                    >
                    <Box display="flex" flexDirection="row" width="100%" alignItems="center" mb="16px">
                        <span className="heading-07-compact">{t('components.subscriptionOptions.enterprise.title')}</span>
                        <Box display="flex" flexGrow={1} />
                        {currentPlan?.includes('enterprise') ?
                            <Tag
                                label={t('components.tag.labels.currentPlan')}
                                colour="blue"
                                outlined
                                sx={{mb: '2px'}}
                                /> :
                            <span className="body-02-compact">{t('components.subscriptionOptions.enterprise.price')}</span>}
                        <Radio 
                            value="enterprise" 
                            size="small" 
                            checked={isEnterprise} 
                            disabled={disabled}
                            onChange={handleSelectPlan} 
                            sx={{
                                padding: 0, 
                                marginLeft: '16px',
                                '& .MuiSvgIcon-root': {color: isEnterprise ? 'var(--focus)' : 'unset'}
                            }} 
                            />
                    </Box>
                    <span className="body-02-compact" style={{textAlign: 'left'}}>{t('components.subscriptionOptions.enterprise.description')}</span>
                    <Toggle
                        name="annual"
                        control={control}
                        size="small"
                        label={<Box display="flex">
                            <span className="body-02-compact" style={{textAlign: 'left'}}>{t('components.toggle.enterpriseAnnual.title')}</span>
                            <span className="heading-07-compact" style={{textAlign: 'left', marginLeft: '4px', color: 'var(--support-success)'}}>{t('components.toggle.enterpriseAnnual.saving')}</span>
                        </Box>}
                        disabled={isStandard || disabled}
                        sx={{marginTop: '16px'}}
                        />
                </Box>

            </RadioGroup>

        </Box>
    )
}

export default SubscriptionOptions;