import { useState } from "react";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { selectHasTeam, selectIsOwner } from "@/store/team";
import { selectEndsAt, selectIsTrial } from "@/store/subscription";
import { useGetPaymentMethodsQuery } from "@/repositories/payment_methods";
import { useRenewSubscriptionMutation } from "@/repositories/subscription";

// Components
import { Box } from "@mui/material";
import PayNowModal from "./pay_now_modal";
import FullScreenLoader from "@/components/overlays/fullscreen_loader";
import { InlineNotification } from "@/components/notification/notification";


const ErrorNotification = () => {

    const { t } = useTranslation();
    const ends_at = useSelector(selectEndsAt);
    const isTrial = useSelector(selectIsTrial);
    const hasTeam = useSelector(selectHasTeam);
    const isOwner = useSelector(selectIsOwner);
    const [open, setOpen] = useState<boolean>(false);
    const { data: paymentMethods } = useGetPaymentMethodsQuery(undefined);
    const [renewSubscription, { isLoading }] = useRenewSubscriptionMutation();
    const hasFailure = paymentMethods?.some((item) => item.failure);

    const hideNotification = (!ends_at && !hasFailure) || (hasTeam && !isOwner);

    const submitRenewSubscription = () => {
        renewSubscription().unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.billing.subscriptionRenewed.title'), message: t('notifications.billing.subscriptionRenewed.message')})
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    if (hideNotification) return null;

    return (
        <>
            <FullScreenLoader show={isLoading} loadingText={t(`billing.${isTrial ? 'reactivatingTrial' : 'renewingSubscription'}`)} />
            {ends_at && <InlineNotification 
                type="error"
                title={t(`inlineNotifications.billing.${isTrial ? 'trialEnding' : 'subscriptionEnding'}.title`)}
                message={<Trans
                    i18nKey={`inlineNotifications.billing.${isTrial ? 'trialEnding' : 'subscriptionEnding'}.message`}
                    components={{ strong: <strong /> }}
                    values={{ date: new Date(ends_at).toLocaleDateString() }}
                />}
                action={{
                    label: t(`components.buttons.${isTrial ? 'reactivateTrial' : 'renewSubscription'}`),
                    onClick: submitRenewSubscription
                }}
                />}

            {hasFailure && <InlineNotification 
                type="error"
                title={t('inlineNotifications.billing.paymentFailed.title')}
                message={t('inlineNotifications.billing.paymentFailed.message')}
                action={{
                    label: t('components.buttons.payNow'),
                    onClick: () => setOpen(true)
                }}
                />}
            <PayNowModal open={open} onClose={() => setOpen(false)} />

            {/* Spacer */}
            <Box height="24px" />
        </>
    )
}

export default ErrorNotification;