import { t } from "i18next";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { zodResolver } from "@hookform/resolvers/zod";
import { Trans, useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { selectSubscriptionType } from "@/store/subscription";
import { useGetUpcomingInvoiceQuery } from "@/repositories/invoice";
import IPaymentMethod from "@/interfaces/payment_methods/payment_method";
import { useGetPaymentMethodsQuery } from "@/repositories/payment_methods";
import { useEnableModuleMutation } from "@/repositories/subscription_module";
import IModuleForm, { moduleFormSchema } from "@/interfaces/storefront/module_form";

// Styes
import { ArrowRight } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Modal from "@/components/modal/modal";
import SelectPaymentMethod from "./select_payment_method";


interface _BuyPaymentsModuleModalProps {
    open: boolean;
    onClose: () => void;
}

const BuyPaymentsModuleModal: FC<_BuyPaymentsModuleModalProps> = ({
    open,
    onClose
}) => {

    const { t } = useTranslation();
    const subscriptionPlan = useSelector(selectSubscriptionType);
    const [enablePaymentsModule, { isLoading: isEnablingModule }] = useEnableModuleMutation();
    const { data: paymentMethods, isLoading: isLoadingPaymentMethods } = useGetPaymentMethodsQuery(undefined);

    const formMethods = useForm<IModuleForm>({
        resolver: zodResolver(moduleFormSchema),
        mode: 'onBlur',
        defaultValues: {
            payment_method: undefined,
            module: subscriptionPlan === 'enterprise_annual' ? 'payments_annual' : 'payments'
        }
    });

    const selectedCard = formMethods.watch('payment_method');

    useEffect(() => {
        if (paymentMethods && !selectedCard) {
            const card = paymentMethods.find((item) => item.default == true);
            if (card) {
                formMethods.setValue('payment_method', card);
            }
        }
    }, [paymentMethods, selectedCard])

    const handleClose = () => {
        formMethods.reset();
        onClose();
    }

    const submit = (data: IModuleForm) => {
        const payload = {
            payment_method_id: data.payment_method.id,
            module: data.module
        }
        enablePaymentsModule(payload).unwrap().then(() => {
            showToast({
                type: 'success',
                title: t('notifications.billing.paymentModuleEnabled.title'),
                message: t('notifications.billing.paymentModuleEnabled.message')
            });
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Modal  
            open={open}
            title={t('modals.enableAddOn')}
            children={
                <FormProvider {...formMethods}>
                    <PaymentsModuleForm
                        paymentMethods={paymentMethods ?? []}
                        isLoading={isLoadingPaymentMethods}
                        selectedCard={selectedCard}
                        />
                </FormProvider>
            }
            action1={{
                label: t('components.buttons.enableAddOn'),
                icon: <ArrowRight />,
                loading: isEnablingModule,
                onClick: formMethods.handleSubmit(submit),
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isEnablingModule,
                onClick: handleClose,
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '660px', width: '100%', background: 'var(--background)', boxSizing: 'border-box'}}}
            />
    )
}

export default BuyPaymentsModuleModal;


interface _PaymentsModuleFormProps {
    paymentMethods: IPaymentMethod[];
    isLoading: boolean;
    selectedCard?: IPaymentMethod;
}

const PaymentsModuleForm: FC<_PaymentsModuleFormProps> = ({
    paymentMethods,
    isLoading,
    selectedCard,
}) => {

    const { data: invoice } = useGetUpcomingInvoiceQuery(undefined);
    const nextBillingDate = invoice ? new Date(invoice.date).toLocaleDateString() : '-';

    return (
        <Box display="flex" flexDirection="column" rowGap="16px">

            <Box 
                display="flex"
                justifyContent="space-between"
                padding="24px 32px"
                border="solid 1px var(--teal-60)"
                borderRadius="6px"
                bgcolor="var(--layer-01)"
                >

                {/* Title and description */}
                <Box display="flex" flexDirection="column">
                    <span className="heading-06" style={{marginBottom: '20px'}}>{t('pages.business.setup.title')}</span>
                    <Box display="flex" flexDirection="column" px="8px">
                        <span className="body-02">{t('pages.settings.billing.paymentsModule.productsText')}</span>
                        <span className="body-02">{t('pages.settings.billing.paymentsModule.paymentsText')}</span>
                        <span className="body-02">{t('pages.settings.billing.paymentsModule.brandingText')}</span>
                        <span className="body-02" style={{marginBottom: '6px'}}>{t('pages.settings.billing.paymentsModule.performanceText')}</span>
                    </Box>

                </Box>
                
            </Box>

            <SelectPaymentMethod
                selectedCard={selectedCard}
                paymentMethods={paymentMethods}
                isLoading={isLoading}
                />

            <span className="body-02-compact">{<Trans
                i18nKey="pages.settings.billing.paymentsModule.infoTextFOC"
                components={{ strong: <strong /> }}
                values={{ date: nextBillingDate}}
                />}</span>

        </Box>
    )
}