
import { createSlice } from "@reduxjs/toolkit";
import IPagination from "@/interfaces/api/pagination";
import { CardSetting } from "@/pages/client/nutrition/totals_card";


interface PageStatesState {
    clientsTab: string;
    clientsTable: IPagination;
    nutritionCardSetting: CardSetting;
}

const initialState: PageStatesState = {
    clientsTab: "/clients/active",
    clientsTable: {page: 1, limit: 10},
    nutritionCardSetting: localStorage.getItem('nutritionCardSetting') as CardSetting ?? 'average'
};

export const pageStatesSlice = createSlice({
  name: "pageStates",
  initialState,
  reducers: {
    setClientsTab: (state, { payload }) => {
      state.clientsTab = payload;
    },
    setClientsTable: (state, { payload }) => {
      state.clientsTable = payload;
    },
    setNutritionCardSetting: (state, { payload }) => {
      state.nutritionCardSetting = payload;
      localStorage.setItem('nutritionCardSetting', payload);
    }
  },
  selectors: {
    selectClientsTab: (state): string => state.clientsTab,
    selectClientsTablePagination: (state): IPagination => state.clientsTable,
    selectNutritionCardSetting: (state): CardSetting => state.nutritionCardSetting
  }
});

export const { 
  selectClientsTab, 
  selectClientsTablePagination,
  selectNutritionCardSetting
} = pageStatesSlice.selectors;

export const { 
  setClientsTab, 
  setClientsTable,
  setNutritionCardSetting
} = pageStatesSlice.actions;

export default pageStatesSlice.reducer;