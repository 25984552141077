    import { CSS } from '@dnd-kit/utilities';
    import { BaseSyntheticEvent } from "react";
    import { useSortable } from "@dnd-kit/sortable";

    // Interfaces
    import { ColDef } from "../datatable";

    // Components
    import { Box, TableCell, TableRow } from "@mui/material"
    import { Checkbox, CheckboxCheckedFilled } from "@carbon/icons-react";


    interface _RowProps<T> {
        row: T;
        index: number;
        cols: ColDef<T>[];
        dataKey: keyof T;
        rowClick?: ((row: T) => void);
        onSelect?: ((key: string) => void);
        isSelected?: boolean;
    }

    const Row = <T extends object>({
        row,
        index,
        cols,
        dataKey,
        rowClick,
        onSelect,
        isSelected = false,
    }: _RowProps<T>) => {

        const { node, setNodeRef, transform, transition, isDragging } = useSortable({
            id: String(row[dataKey])
        });

        const style = {
            transform: CSS.Transform.toString(transform),
            transition,
            opacity: isDragging ? 0.5 : 1,
        };

        // Row click handler - ensures only row clicks originating from the table row (TD) are handled
        const handleRowClick = (e: BaseSyntheticEvent, row: T) => {
            if (node.current && !node.current.contains(e.target)) return;

            const tagName = e.target.tagName;
            if (!['TD', 'SPAN', 'DIV'].includes(tagName)) return;
            
            e.stopPropagation();
            rowClick?.(row);
        }

        return ( 
            <TableRow 
                ref={setNodeRef} 
                key={`${row[dataKey]}`}
                onClick={(e) => handleRowClick(e, row)} 
                style={style}
                sx={{ 
                    '&:hover': rowClick ? { bgcolor: 'var(--layer-01-hover)', cursor: 'pointer' } : {},
                    '&:last-child td': {borderBottom: 'none'},
                }}>

            {onSelect && (
                <TableCell onClick={()=>onSelect?.(row[dataKey] as string)}>
                    <Box display='flex' alignItems='center' sx={{cursor: 'pointer'}}>
                        {isSelected ? <CheckboxCheckedFilled size={20} /> : <Checkbox size={20} />}
                    </Box>
                </TableCell>
            )}
            {cols.map((c) => 
                <TableCell key={`${row[dataKey]}-${c.field}`} align={c.align} sx={{width: c.width ?? 'unset', borderBottom: 'unset'}}>
                    <span className="body-02-compact TwoLine--ellipsis" style={{color: 'var(--text-secondary)'}}>
                        {c.render ? c.render(row, index) : `${row[c.field as keyof T]}`}
                    </span>
                </TableCell>
            )}
            </TableRow>
        )
    }

    export default Row;