

import { z } from "zod";
import dayjs from "dayjs";
import { t } from "i18next";
import { Plan } from "./subscription";
import ruleset, { addIssue } from "@/_helpers/ruleset";
import IPaymentMethod from "@/interfaces/payment_methods/payment_method";


export interface ISubscriptionForm {
    payment_method_id: string;
    plan: Plan;
}

export default interface IChangeSubscriptionForm {
    payment_method: IPaymentMethod;
    plan: Plan;
}

export const changeSubscriptionFormSchema = z.object({
    payment_method: ruleset.selectedPaymentMethod,
    plan: ruleset.required
}).superRefine((data, ctx) => {
    if (data.payment_method && dayjs().isAfter(dayjs(`${data.payment_method.exp_year}-${data.payment_method.exp_month}-01`), "month")) addIssue(ctx, ['payment_method'], t('inputs.errors.paymentMethodExpired'));
});