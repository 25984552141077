import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Services and interfaces
import { selectCoachId } from "@/store/coach";
import IClient from "@/interfaces/client/client";
import IPagination from "@/interfaces/api/pagination";
import { useGetClientsQuery } from "@/repositories/client";
import { selectHasTeam, selectSelectedMember } from "@/store/team";
import { selectClientsTablePagination, setClientsTab, setClientsTable } from "@/store/page_states";

// Styles
import { Add } from "@carbon/icons-react";

// Components
import NoClients from "./no_clients";
import { Container } from "@mui/material";
import DataTable from "@/components/datatable";
import useClientTableHeaders from "./table_headers";
import ClientModal from "@/pages/client/components/client_modal";


const InactiveTab: FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const headers = useClientTableHeaders();
    const hasTeam = useSelector(selectHasTeam);
    const coachId = useSelector(selectCoachId);
    const selectedMember = useSelector(selectSelectedMember);
    const pagination = useSelector(selectClientsTablePagination);
    const [open, setOpen] = useState<boolean>(false);

    const { data, isLoading } = useGetClientsQuery(hasTeam ? selectedMember?.id : undefined, {
        skip: hasTeam && !selectedMember
    });
    const list = data?.filter(c => !c.active) ?? [];

    const noDataMessage = () => {
        if (!hasTeam) return t('components.dataTable.noData.noClients');
        if (selectedMember?.id !== coachId) return t('components.dataTable.noData.noTeamClients', {name: selectedMember?.name});
    }

    const handlePageChange = (pagination: IPagination) => {
        dispatch(setClientsTable(pagination));
    }

    const onRowClick = (row: IClient) => {
        const currentTab = location.pathname;
        dispatch(setClientsTab(currentTab));
        navigate(`/clients/${row.id}/overview`);
    }

    return (
        <Container sx={{padding: '24px 0', overflowY: 'auto'}}>

            <DataTable 
                data={list}
                columns={headers}
                hideHeader
                filter={{
                    button: {
                        label: t('components.buttons.addClient'),
                        icon: <Add />,
                        onClick: () => setOpen(true)
                    }
                }}
                filterKeys={["full_name", "email"]}
                isLoading={isLoading}
                rowClick={onRowClick}
                localPagination
                initialPage={pagination.page}
                initialPageLimit={pagination.limit}
                onChange={handlePageChange}
                noDataMessage={<NoClients message={noDataMessage()} disabled={hasTeam && selectedMember?.id !== coachId} />}
                noDataFoundMessage={<NoClients disabled={hasTeam && selectedMember?.id !== coachId} />}
                />
                {open && <ClientModal 
                    open={open} 
                    onClose={() => setOpen(false)} 
                    />}
                
        </Container>
    )
}

export default InactiveTab;