import { authApi } from "@/repositories/auth";
import { createSlice } from "@reduxjs/toolkit";
import { Module, Plan } from "@/interfaces/subscription/subscription";
import { subscriptionApi } from "@/repositories/subscription";
import { subscriptionModuleApi } from "@/repositories/subscription_module";
import { ISubscription, ISubscriptionItems } from "@/interfaces/subscription/subscription";


interface IState {
  loaded: boolean;
  id: number | null;
  type: Plan;
  stripe_status: string;
  ends_at: string;
  quantity: number;
  items: ISubscriptionItems[];
}

const initialState: IState = {
  loaded: false,
  id: null,
  type: 'standard',
  stripe_status: "",
  ends_at: "",
  quantity: 0,
  items: [],
};


export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(subscriptionApi.endpoints.getSubscription.matchFulfilled, (state, { payload }) => {
      updateState(state, payload)
    }).addMatcher(subscriptionApi.endpoints.createSubscription.matchFulfilled, (state, { payload }) => {
      updateState(state, payload)
    }).addMatcher(subscriptionApi.endpoints.changeSubscription.matchFulfilled, (state, { payload }) => {
      updateState(state, payload)
    }).addMatcher(subscriptionApi.endpoints.cancelDowngradeSubscription.matchFulfilled, (state, { payload }) => {
      updateState(state, payload)
    }).addMatcher(subscriptionApi.endpoints.cancelSubscription.matchFulfilled, (state, { payload }) => {
      updateState(state, payload)
    }).addMatcher(subscriptionApi.endpoints.renewSubscription.matchFulfilled, (state, { payload }) => {
      updateState(state, payload)
    }).addMatcher(subscriptionModuleApi.endpoints.enableModule.matchFulfilled, (state, { payload }) => {
      updateState(state, payload)
    }).addMatcher(subscriptionModuleApi.endpoints.cancelModule.matchFulfilled, (state, { payload }) => {
      updateState(state, payload)
    }).addMatcher(subscriptionModuleApi.endpoints.renewModule.matchFulfilled, (state, { payload }) => {
      updateState(state, payload)
    }).addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
      resetState(state);
    })
  },
  selectors: {
    selectSubscriptionHydrated: (state): boolean => state.loaded,
    selectIsSubscribed: (state): boolean => ['active', 'trialing', 'past_due'].includes(state.stripe_status),
    selectSubscriptionType: (state): Plan => state.type,
    selectIsAnnualSubscription: (state): boolean => state.type === 'enterprise_annual',
    selectIsTrial: (state): boolean => state.stripe_status === 'trialing',
    selectEndsAt: (state): string => state.ends_at,
    selectPastDue: (state): boolean => state.stripe_status === 'past_due',
    selectPaymentsEnabled: (state): boolean => state.items.some((item) => item.type.includes('payments')),
    selectPaymentsCancelled: (state): boolean => state.items.some((item) => item.type.includes('payments') && !!item.cancelled_at),
    selectPaymentsModuleType: (state): Module|undefined => state.items.find((item) => item.type.includes('payments'))?.type as Module|undefined,
    selectDowngradePending: (state): boolean => state.items.some((item) => item.pending_change === 'downgrade'),
  }
});

const updateState = (state: IState, payload: ISubscription) => {
  state.id = payload.id;
  state.type = payload.type,
  state.stripe_status = payload.stripe_status;
  state.ends_at = payload.ends_at;
  state.quantity = payload.quantity;
  state.items = payload.items;
  state.loaded = true;
}

const resetState = (state: IState) => {
  state.loaded = false;
  state.id = null;
  state.stripe_status = "";
  state.ends_at = "";
  state.quantity = 0;
  state.items = [];
}

export const { 
  selectSubscriptionHydrated, 
  selectIsSubscribed, 
  selectSubscriptionType,
  selectIsAnnualSubscription,
  selectIsTrial,
  selectEndsAt, 
  selectPastDue,
  selectPaymentsEnabled,
  selectPaymentsCancelled,
  selectPaymentsModuleType,
  selectDowngradePending,
} = subscriptionSlice.selectors;

export default subscriptionSlice.reducer;
