import { t } from "i18next";
import { useMemo, ReactNode } from "react";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";

// Table Components
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';

// Components
import Row from "./row";
import { Card, Skeleton } from "@mui/material";
import useSort from "@/components/sortable/sort_hooks";


interface RowData { 
    id: string | number
    [key: string]: string | number
}

interface ColDef<T> {
    field: string;
    headerName: string;
    width?: string;
    align?: 'left' | 'center' | 'right';
    render?: ((row: T, index: number) => ReactNode);
}

interface TableProps<T> {
    size?: 'small' | 'medium' | 'large' | 'extralarge';
    data?: T[];
    dataKey?: keyof T;
    columns?: ColDef<T>[];
    prefixRow?: ReactNode;
    suffixRow?: ReactNode;
    hideHeader?: boolean;
    noDataMessage?: string|ReactNode;
    isLoading?: boolean;
    onReorder?: (oldIndex: number, newIndex: number) => void;
}

const BuilderTable = <T extends object>({
    size = 'large',
    data = [],
    dataKey = 'id' as keyof T,
    columns,
    prefixRow,
    suffixRow,
    hideHeader = false,
    noDataMessage = t('components.dataTable.noData.default'),
    isLoading = false,
    onReorder,
  }: TableProps<T>) => {

    const { restrictToVerticalAxis } = useSort();

    // Check for column definitions, if null, make own based on fields in rows
    const cols: ColDef<T>[] = useMemo(() => {
        if (!columns) {
            return Object.keys(data[0] ?? {}).map((k) => ({
                field: k,
                headerName: k
                    .replace(/([A-Z])/, "-$1")
                    .split("-")
                    .map((s) => s[0].toUpperCase() + s.substring(1))
                    .join(" "),
            }));
        }
        return columns;
        // return onReorder ? 
        //     [{
        //         field: "drag",
        //         headerName: "",
        //         width: "48px"
        //     }, ...columns]
        //     : columns;
    }, [columns, data])

    const handleDragEnd = ({ active, over }: { active: any; over: any }) => {
        if (!over || active.id === over.id) return;
        const oldIndex = data.findIndex((item) => String(item[dataKey]) === active.id);
        const newIndex = data.findIndex((item) => String(item[dataKey]) === over.id);
        onReorder?.(oldIndex, newIndex);
    };

    return (
        <Card elevation={0} sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            bgcolor: 'transparent',
            border: 'none', 
            borderRadius: '6px',
        }}>

            <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]}>
                <SortableContext items={data.map((row) => String(row[dataKey]))} strategy={verticalListSortingStrategy}>
                    <Table className={`BuilderTable BuilderTable--${size}`}>
                        {/* Column Headers */}
                        {!hideHeader && <TableHead>
                            <TableRow sx={{'& th': {borderBottom: 'none'}}}>
                                {cols.map((c) => (
                                    <TableCell key={c.field} sx={{width: c.width ?? 'unset'}}>
                                        <span className="heading-07-compact">{c.headerName}</span>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>}

                        {/* Body */}
                        <TableBody>
                            {/* loading state */}
                            {isLoading && Array(3).fill(0).map((_, i) => (
                                <TableRow key={i}>
                                    {cols.map((c) => (
                                        <TableCell key={`${i}_${c.field}`}>
                                            <span className="body-02-compact text-secondary">
                                                <Skeleton variant="text" width={100} />
                                            </span>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}

                            {prefixRow}

                            {/* no data */}
                            {!isLoading && data.length === 0 && 
                                <TableRow>
                                    <TableCell colSpan={cols.length} sx={{
                                        textAlign: 'center', border: 'none',
                                        verticalAlign: 'center',
                                        height: 'unset'
                                    }}>
                                        <span className="body-02-compact TwoLine--ellipsis" style={{color: 'var(--text-secondary)'}}>
                                            {noDataMessage}
                                        </span>
                                    </TableCell>
                                </TableRow>}

                            {/* data */}
                            {data.map((row, index) => (
                                <Row 
                                    key={String(row[dataKey])} 
                                    row={row} 
                                    index={index} 
                                    cols={cols} 
                                    dataKey={dataKey}
                                />
                            ))}

                            {suffixRow}

                        </TableBody>

                    </Table>
                </SortableContext>
            </DndContext>
        </Card>
    );
}

export type { ColDef, RowData };
export default BuilderTable;