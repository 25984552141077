import { FC } from "react";
import { useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { useCancelDowngradeSubscriptionMutation } from "@/repositories/subscription";

// Styes
import { ArrowRight } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";


interface _CancelDowngradeModalProps {
    open: boolean;
    onClose: () => void;
}

const CancelDowngradeModal: FC<_CancelDowngradeModalProps> = ({
    open,
    onClose,
}) => {

    const { t } = useTranslation();
    const [cancelDowngrade, {isLoading: isCancelling}] = useCancelDowngradeSubscriptionMutation();

    const handleCancelDowngrade = () => {
        cancelDowngrade().unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.billing.downgradeCancelled.title'),
                message: t('notifications.billing.downgradeCancelled.message')
            })
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Modal
            open={open}
            onClose={isCancelling ? undefined : onClose}
            title={t('modals.cancelDowngrade.title')}
            text={t('modals.cancelDowngrade.text')}
            action1={{
                kind: "danger-ghost",
                label: t('components.buttons.cancelDowngrade'),
                icon: <ArrowRight />,
                onClick: handleCancelDowngrade,
                loading: isCancelling,
            }}

            cancel={{
                label: t('components.buttons.cancel'),
                onClick: onClose,
                disabled: isCancelling
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '480px', background: 'var(--background)'}}}
            />
    )
}

export default CancelDowngradeModal;