import { FC, useState } from "react"
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { selectInvitation } from "@/store/team";
import { ApiError } from "@/interfaces/api/error";
import { useGetClientsQuery } from "@/repositories/client";
import { selectIsAnnualSubscription } from "@/store/subscription";
import { selectPaymentGatewayConfigured } from "@/repositories/storefront";
import { useAcceptInvitationMutation, useDeclineInvitationMutation } from "@/repositories/team_invitation";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Button from "@/components/button";
import FullScreenLoader from "@/components/overlays/fullscreen_loader";
import { InlineNotification } from "@/components/notification/notification";


const ManageInvitation: FC = () => {

    const { t } = useTranslation();
    const invitation = useSelector(selectInvitation);
    const [confirmed, setConfirmed] = useState(false);
    const { data: clients } = useGetClientsQuery(undefined);
    const isAnnual = useSelector(selectIsAnnualSubscription);
    const paymentsConfigured = useSelector((state) => selectPaymentGatewayConfigured(state, undefined));
    const hasSubscriptions = clients?.some((c) => c.product || c.checkout_session);
    const canAccept = !paymentsConfigured && !hasSubscriptions;

    const [acceptInvitation, { isLoading: acceptLoading }] = useAcceptInvitationMutation();
    const [declineInvitation, { isLoading: declineLoading }] = useDeclineInvitationMutation();

    const handleDeclineInvite = async () => {
        await declineInvitation().unwrap().then(() => {
            showToast({
                type: 'info', 
                title: t('notifications.teamInvitation.declineSuccessTitle'), 
                message: t('notifications.teamInvitation.declineSuccessMessage')
            });
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type});
        });
    }

    const handleAcceptInvite = async () => {
        await acceptInvitation().unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.teamInvitation.acceptSuccessTitle'), 
                message: t('notifications.teamInvitation.acceptSuccessMessage')
            });
        }).catch((error: ApiError) => {
            console.log('error', error);
            showToast({type: 'error', apiError: error.type});
        });
    }

    return (
        <>
            <FullScreenLoader show={acceptLoading} loadingText={t('pages.settings.team.invitation.acceptingInvitation')} />
            <Box display="flex" flexDirection="column" textAlign="left" rowGap="16px">

                {!paymentsConfigured && <InlineNotification 
                    type={confirmed ? "success" : "info"}
                    title={t('inlineNotifications.team.acceptInvite.title')}
                    message={t('inlineNotifications.team.acceptInvite.message')}
                    action={{
                        label: t('components.buttons.confirm'),
                        onClick: () => setConfirmed(true),
                        disabled: confirmed
                    }}
                    />}
                {paymentsConfigured && <InlineNotification 
                    type="warning"
                    title={t('inlineNotifications.team.preventAcceptInvite.title')}
                    message={t('inlineNotifications.team.preventAcceptInvite.message', {context: `${hasSubscriptions}`})}
                    />}

                <span className="heading-07">{t('pages.settings.team.invitation.title', {team: invitation?.team.name, name: invitation?.invited_by})}</span>

                {paymentsConfigured ? 
                    <span className="body-02" style={{color: 'var(--text-secondary)', whiteSpace: 'pre-wrap'}}>{t('pages.settings.team.invitation.textPayEnabled')}</span> :
                    <span className="body-02" style={{color: 'var(--text-secondary)', whiteSpace: 'pre-wrap'}}>{
                        <Trans
                            i18nKey="pages.settings.team.invitation.text"
                            components={{strong: <strong />}}
                            values={{context: `${isAnnual}`}}
                            />
                    }</span>}

                {/* Spacer */}
                <Box height="32px" />

                {canAccept && <Box display="flex" justifyContent="end" columnGap="16px">

                    <Button
                        kind="danger-secondary"
                        label={t('components.buttons.decline')}
                        loading={declineLoading}
                        disabled={acceptLoading}
                        onClick={handleDeclineInvite}
                        />
                    <Button
                        label={t('components.buttons.accept')}
                        endIcon={<ArrowRight />}
                        loading={acceptLoading}
                        disabled={!confirmed || declineLoading}
                        onClick={handleAcceptInvite}
                        />

                </Box>}

            </Box>
        </>
    )
}

export default ManageInvitation;