import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import { useSortable } from "@dnd-kit/sortable";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { BaseSyntheticEvent, FC, useEffect, useRef, useState } from "react";

// Constants
import { roundNumber } from "@/_helpers/number_functions";

// Services and interfaces
import { IMealFood, INutritionBuilder } from "@/interfaces/nutrition/nutrition_builder";

// Styles
import { Add, ChevronDown, DragVertical, WarningFilled } from "@carbon/icons-react";

// Components
import AddModal from "./add_modal";
import MealOptions from './meal_options';
import Button from "@/components/button";
import useFoodHeaders from "./food_headers";
import TextInput from "@/components/text_input";
import NumberInput from "@/components/number_input";
import OverflowMenu from "@/components/overflow_menu";
import BuilderTable from "@/components/builder/table";
import DragHandle from "@/components/sortable/drag_handle";
import DeleteModal from "@/components/builder/delete_modal";
import { Accordion, AccordionDetails, AccordionSummary, accordionSummaryClasses, Box, Table, TableBody, TableCell, TableRow } from "@mui/material";


interface _MealProps {
    id: string;
    dayIndex: number;
    mealIndex: number;
    onRemoveMeal: (index: number) => void;
}

const Meal: FC<_MealProps> = ({
    id,
    dayIndex,
    mealIndex,
    onRemoveMeal
}) => {

    const { t } = useTranslation();
    const mealRef = useRef(null);
    const { setNodeRef } = useSortable({ id });
    const [open, setOpen] = useState<string|null>(null);
    const [mealExpanded, setMealExpanded] = useState<boolean>(false);
    const [currentFoods, setCurrentFoods] = useState<string[]>([]); //REVIEW - why is this a state?
    const { control, setValue, formState: { errors } } = useFormContext<INutritionBuilder>();

    const hasErrors = errors.plan?.[dayIndex]?.meals?.[mealIndex];
    const foods = useWatch({ control, name: `plan.${dayIndex}.meals.${mealIndex}.foods` });
    const { fields, append, remove, move } = useFieldArray({
        control,
        name: `plan.${dayIndex}.meals.${mealIndex}.foods`
    });

    const meals = useWatch({ control, name: `plan.${dayIndex}.meals` });
    const meal = useWatch({ control, name: `plan.${dayIndex}.meals.${mealIndex}` });
    const calories = useWatch({ control, name: `plan.${dayIndex}.meals.${mealIndex}.calories` });
    const protein = useWatch({ control, name: `plan.${dayIndex}.meals.${mealIndex}.protein` });
    const carbs = useWatch({ control, name: `plan.${dayIndex}.meals.${mealIndex}.carbs` });
    const fat = useWatch({ control, name: `plan.${dayIndex}.meals.${mealIndex}.fat` });

    const headers = useFoodHeaders({ dayIndex, mealIndex, onRemoveFood: remove });

    useEffect(() => {
        const p = roundNumber(meals.reduce((acc, meal) => acc + (meal.protein == '' ? 0 : meal.protein as number), 0), 1);
        const c = roundNumber(meals.reduce((acc, meal) => acc + (meal.carbs == '' ? 0 :meal.carbs as number), 0), 1);
        const f = roundNumber(meals.reduce((acc, meal) => acc + (meal.fat == '' ? 0 : meal.fat as number), 0), 1);
        const fi = roundNumber(meals.reduce((acc, meal) => acc + (meal.fibre == '' ? 0 : meal.fibre as number), 0), 1);

        setValue(`plan.${dayIndex}.protein`, p);
        setValue(`plan.${dayIndex}.carbs`, c);
        setValue(`plan.${dayIndex}.fat`, f);
        setValue(`plan.${dayIndex}.fibre`, fi);
    }, [meal.calories, meal.fibre]);

    const handleChange = (e: BaseSyntheticEvent) => {
        const classList = e.target.classList;
        if (!classList.contains('_Expandable') || classList.contains('MuiAccordionSummary-content')) return;
        e.stopPropagation();
        setMealExpanded(!mealExpanded);
    }

    const handleMacroChange = (macro: string, value: number) => {
        const p = (macro === 'protein' ? value : Number(protein)) * 4;
        const c = (macro === 'carbs' ? value : Number(carbs)) * 4;
        const f = (macro === 'fat' ? value : Number(fat)) * 9;
        const kcal = p + c + f;
        setValue(`plan.${dayIndex}.meals.${mealIndex}.calories`, kcal, { shouldDirty: true });
    }

    const handleOpenAddFoods = () => {
        setCurrentFoods(foods.map(f => f._id));
        setOpen('add');
    }

    const handleCloseAddFoods = () => {
        setOpen(null);
        setCurrentFoods([]);
    }

    const handleAddFoods = (foods: IMealFood[]) => {
        append(foods);
        setOpen(null);
        setCurrentFoods([]);
        if (!mealExpanded) setMealExpanded(true);
    }

    const handleReOrderFoods = (oldIndex: number, newIndex: number) => {
        move(oldIndex, newIndex);
    }

    const handleDeleteMeal = () => {
        if (meal.foods.length > 0) return setOpen('delete');
        onRemoveMeal(mealIndex);
    }

    const addMealOptions = () => {
        // add two empty options to the meal
        setValue(`plan.${dayIndex}.meals.${mealIndex}.options`, [
            { uuid: uuidv4(), calories: meal.calories, protein: meal.protein, carbs: meal.carbs, fat: meal.fat, fibre: meal.fibre, foods: meal.foods },
            { uuid: uuidv4(), calories: 0, protein: 0, carbs: 0, fat: 0, fibre: 0, foods: [] }
        ]);

        // clear foods array
        setValue(`plan.${dayIndex}.meals.${mealIndex}.foods`, [])

        // clear macros if no foods set
        if (meal.foods.length == 0) {
            setValue(`plan.${dayIndex}.meals.${mealIndex}.calories`, 0)
            setValue(`plan.${dayIndex}.meals.${mealIndex}.fat`, 0)
            setValue(`plan.${dayIndex}.meals.${mealIndex}.protein`, 0)
            setValue(`plan.${dayIndex}.meals.${mealIndex}.carbs`, 0)
            setValue(`plan.${dayIndex}.meals.${mealIndex}.fibre`, 0)
        }

        if (!mealExpanded) setMealExpanded(true)
    }

    const removeMealOptions = () => {
        // move meal options to foods
        setValue(`plan.${dayIndex}.meals.${mealIndex}.foods`, meal.options[0].foods)
        setValue(`plan.${dayIndex}.meals.${mealIndex}.options`, []);
        
        // clear macros if no foods set
        if (meal.options[0].foods.length == 0) {
            setValue(`plan.${dayIndex}.meals.${mealIndex}.calories`, 0)
            setValue(`plan.${dayIndex}.meals.${mealIndex}.fat`, 0)
            setValue(`plan.${dayIndex}.meals.${mealIndex}.protein`, 0)
            setValue(`plan.${dayIndex}.meals.${mealIndex}.carbs`, 0)
            setValue(`plan.${dayIndex}.meals.${mealIndex}.fibre`, 0)
        }

        if (!mealExpanded) setMealExpanded(true)
    }

    return (
        <Box ref={setNodeRef} display="flex" flexDirection="column" borderRadius="6px" border="solid 1px var(--border-subtle-01)" marginBottom="24px" sx={{bgcolor: 'var(--layer-01)'}}>

            <Accordion 
                ref={mealRef}
                expanded={mealExpanded}
                onChange={handleChange}
                slotProps={{ transition: { unmountOnExit: true } }} sx={{
                    m: 0, 
                    width: '100%', 
                    boxShadow: '0', 
                }}>

                <AccordionSummary
                    expandIcon={<ChevronDown className="_Expandable" />}
                    sx={{
                        ml: 3, p: 0,
                        flexDirection: 'row-reverse',
                        [`& .${accordionSummaryClasses.content}`]: {
                            m: 0, p: 2,
                            [`&.${accordionSummaryClasses.expanded}`]: {
                                m: 0
                            }
                        }
                    }}>
                    <Box className="_Expandable" display="flex" flexGrow={1} alignItems="center" justifyContent="space-between" padding="0px">

                        <Box display="flex" flexDirection="column" alignItems="flex-start">
                            <Box display="flex" alignItems="center">
                                <span className="body-02 _Expandable">{t('components.nutritionBuilder.meals.meal', {number: mealIndex+1})}</span>
                                {hasErrors && <WarningFilled style={{color: 'var(--support-error)', marginLeft: '8px'}} />}
                            </Box>
                            {!hasErrors?.root?.message && meal.options?.length > 0 && <span className="label-text-03" style={{color: 'var(--text-secondary)'}}>{t('components.nutritionBuilder.meals.options', {count: meal.options.length})}</span>}
                            {hasErrors && <span className="label-text-03" style={{color: 'var(--support-error)'}}>{hasErrors.root?.message}</span>}
                        </Box>

                        <Box display="flex">

                            {(meal.options == null || meal.options?.length == 0) && <Button
                                kind="ghost"
                                size="small"
                                label={t('components.buttons.addFood')}
                                endIcon={<Add />}
                                minWidth={false}
                                onClick={handleOpenAddFoods}
                            />}

                            {open === 'add' && <AddModal
                                open={open === 'add'}
                                selectedFoods={currentFoods}
                                onClose={handleCloseAddFoods}
                                onAdd={handleAddFoods}
                            />}

                            {/* Sort */}
                            {meals.length === 1 ?
                                <Box display="flex" alignItems="center" minWidth="32px" justifyContent="center" marginRight="8px" sx={{color: 'var(--icon-disabled)' }}>
                                    <DragVertical onClick={(e) => e.stopPropagation()} />
                                </Box> :
                                <DragHandle id={id}>
                                    <Box display="flex" alignItems="center" width="32px" height="32px" justifyContent="center" sx={{ cursor: 'grab' }}>
                                        <DragVertical onClick={(e) => e.stopPropagation()} />
                                    </Box>
                                </DragHandle>}

                            <Box width="8px" />

                            <OverflowMenu
                                options={[
                                    meal.options?.length > 0 ?
                                    { 
                                        name: t('components.nutritionBuilder.menu.removeOptions'),
                                        action: removeMealOptions,
                                        disabled: meal.options.length != 1
                                    } :
                                    { 
                                        name: t('components.nutritionBuilder.menu.addOption'), 
                                        action: addMealOptions
                                    }                                     
                                ]}
                                onDelete={handleDeleteMeal}
                            />
                            {open === 'delete' && <DeleteModal
                                open={open === 'delete'}
                                onClose={() => setOpen(null)}
                                title={t('modals.deleteDayMeal.title')}
                                text={t('modals.deleteDayMeal.text')}
                                onDelete={() => onRemoveMeal(mealIndex)}
                            />}

                        </Box>
                    </Box>
                </AccordionSummary>

                <AccordionDetails sx={{p:0, textAlign: 'left'}}>
                    <Box padding="16px">
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ width: '27.5%', padding: '0 8px', borderBottom: 'none' }}>
                                        <TextInput
                                            name={`plan.${dayIndex}.meals.${mealIndex}.name`}
                                            control={control}
                                            label={t('inputs.titles.mealName')}
                                            gutter="0"
                                            hideErrorMessage
                                            maxLength={40}
                                        />
                                    </TableCell>

                                    <TableCell style={{ width: '15%', padding: '0 8px', borderBottom: 'none'  }}>
                                        {/* Empty cell for spacing */}
                                    </TableCell>

                                    <TableCell style={{width: '10%', padding: '0 8px', borderBottom: 'none' }}>
                                        <NumberInput
                                            name={`plan.${dayIndex}.meals.${mealIndex}.protein`}
                                            control={control}
                                            label={t('components.nutritionBuilder.macros.protein')}
                                            gutter="0"
                                            hideErrorMessage
                                            disabled={fields.length > 0 || meal.options?.length > 0}
                                            onChange={(v) => handleMacroChange('protein', v)}
                                        />
                                    </TableCell>
                                    <TableCell style={{width: '10%', padding: '0 8px', borderBottom: 'none' }}>
                                        <NumberInput
                                            name={`plan.${dayIndex}.meals.${mealIndex}.carbs`}
                                            control={control}
                                            label={t('components.nutritionBuilder.macros.carbs')}
                                            gutter="0"
                                            hideErrorMessage
                                            disabled={fields.length > 0 || meal.options?.length > 0}
                                            onChange={(v) => handleMacroChange('carbs', v)}
                                        />
                                    </TableCell>
                                    <TableCell style={{width: '10%', padding: '0 8px', borderBottom: 'none' }}>
                                        <NumberInput
                                            name={`plan.${dayIndex}.meals.${mealIndex}.fat`}
                                            control={control}
                                            label={t('components.nutritionBuilder.macros.fat')}
                                            gutter="0"
                                            hideErrorMessage
                                            disabled={fields.length > 0 || meal.options?.length > 0}
                                            onChange={(v) => handleMacroChange('fat', v)}
                                            />
                                    </TableCell>
                                    <TableCell style={{width: '10%', padding: '0 8px', borderBottom: 'none' }}>
                                        <NumberInput
                                            name={`plan.${dayIndex}.meals.${mealIndex}.fibre`}
                                            control={control}
                                            label={t('components.nutritionBuilder.macros.fibre')}
                                            gutter="0"
                                            hideErrorMessage
                                            disabled={fields.length > 0 || meal.options?.length > 0}
                                        />
                                    </TableCell>
                                    <TableCell style={{width: '10%', padding: '0 8px', borderBottom: 'none' }}>
                                        <Box display="flex" flexDirection="column" alignItems="flex-start" minWidth="75px">
                                            <span className="label-text-02">{t('components.nutritionBuilder.macros.calories')}</span>
                                            <Box height="12px" />
                                            <Box display="flex">
                                                {errors?.plan?.[dayIndex]?.meals?.[mealIndex]?.calories && <WarningFilled style={{color: 'var(--support-error)', marginRight: '8px'}} />}
                                                <span className="body-02">{`${calories.toLocaleString(undefined, {maximumFractionDigits: 1})} ${t('components.nutritionBuilder.macros.kcal')}`}</span>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell style={{width: '6%', padding: '0 8px 0 0', borderBottom: 'none' }}>
                                        {/* Empty cell for spacing */}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                    
                    {meal.options && meal.options.length > 0 && (
                        <MealOptions dayIndex={dayIndex} mealIndex={mealIndex} />
                    )} 

                    {/* Foods table */}
                    {fields.length > 0 && <Box padding="16px" borderTop="solid 1px var(--border-subtle-01)">
                        <BuilderTable
                            dataKey="_id"
                            data={fields}
                            columns={headers}
                            onReorder={handleReOrderFoods}
                        />
                    </Box>}

                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

export default Meal;